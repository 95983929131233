import { Controller } from "@hotwired/stimulus"
import { MarkerClusterer } from "@googlemaps/markerclusterer"


export default class extends Controller {
  static values = { apiKey: String, locations: Array }

  connect () {
    console.log('Connected!')
    console.log(this.apiKeyValue)

    this.loadGoogleMaps(() => this.initMap());
  }

  loadGoogleMaps(callback) {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${this.apiKeyValue}&loading=async&callback=initMap&libraries=places,visualization,marker`;
    document.head.appendChild(script);

    window.initMap = callback;
  }

  initMap() {
    this.map = new google.maps.Map(this.element, {
      zoom: 8,
      center: { lat: 48.836689, lng: 10.097116 },
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      mapId: 'DEMO_MAP_ID'
    });

    if (this.hasLocationsValue) {
      this.addMarkers(this.locationsValue);
    }
  }

  addMarkers(locations) {
    const infoWindow = new google.maps.InfoWindow();

    const markers = locations.map((location) => {
      // return new google.maps.marker.AdvancedMarkerElement({
      //   position: { lat: location.lat, lng: location.lng },
      //   map: this.map,
      //   gmpClickable: true
      // });

      const marker = new google.maps.marker.AdvancedMarkerElement({
        position: { lat: location.lat, lng: location.lng },
        map: this.map,
        gmpClickable: true
      });

      marker.addEventListener("gmp-click", () => {
        infoWindow.setContent(`
          <div>
            <strong>${location.name}</strong><br />
            ${location.information}
          </div>
        `);
        infoWindow.open(this.map, marker);
      });

      return marker;
    });

    new MarkerClusterer({ map: this.map, markers });
  }

  disconnect() {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];

    if (this.infoWindow) {
      this.infoWindow.close();
    }

    if (this.map) {
      this.map = null;
    }
  }
}
